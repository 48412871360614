import { useCompaniesRepository } from '../../../CompaniesPage/hooks/useCompaniesRepository';
import { useEffect } from 'react';

export const useFetchRequired = () => {
    const { fetchAll, isLoading } = useCompaniesRepository();
    useEffect(() => {
        fetchAll();
    }, []);
    return { isLoading };
};
